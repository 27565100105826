import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Column } from '../../Layout';
import { HeroTitle } from '../../Common';
import Competence from '../Competence';

import './CompetencesHero.scss';

const CompetencesHero = (props) => {
    return <section className='competences-hero'>
        <Container>
            <Row>
                <Column sm={7} className='hero-content'>
                    <HeroTitle>Nasze kompetencje</HeroTitle>
                    <p className='hero-description'>
                        Struktura naszej agencji i różnorodne doświadczenie członków zespołu, obejmujące takie dziedziny marketingu, jak PR, influencer i content marketing, eventy, BTL, czy programy wsparcia sprzedaży gwarantują naszym Klientom kompleksowe podejście do stawianych przed nami zadań i odwagę w łączeniu narzędzi w celu osiągnięcia najlepszych rezultatów.

    </p>
                </Column>

            </Row>
            <Row>
                <Column sm={6} md={3}>
                    <Competence index={1} white title="Public relations" />
                </Column>
                <Column sm={6} md={3}>
                    <Competence index={2} primary title="Event marketing" />
                </Column>
                <Column sm={6} md={3}>
                    <Competence index={3} gold title="Content marketing" />
                </Column>
                <Column sm={6} md={3}>
                    <Competence index={4} black title="Wsparcie sprzedaży" />
                </Column>
            </Row>
        </Container>
        <div className='competence-indicator-container'>
            <div className={`competence-indicator competence-${props.index}`}></div>
        </div>
    </section>
}

const mapStateToProps = (state) => (
    {
        index: state.competences.index
    }
);


export default connect(mapStateToProps)(CompetencesHero);