import React from 'react';

import './ProjectPhoto.scss';

const ProjectPhoto = (props) => <img className='project-photo' src={props.img} />

export default ProjectPhoto;

// import React, { Component, Fragment } from 'react';
// import Spinner from './spinner';

// class SmartImage extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             loading: true,
//             visible: true
//         }
//     }

//     imageLoaded = () => {
//         this.setState({
//             loading: false
//         }, () => {
//             setTimeout(() => {
//                 this.setState({
//                     visible: false
//                 })
//             }, 500)
//         })
//     }

//     render() {
//         return <Fragment>
//             <Spinner mode="circle" visible={this.state.visible} loading={this.state.loading} />
//             <img src={this.props.image} className={this.props.imageClass} title={this.props.title} alt={this.props.title} onLoad={this.imageLoaded} />
//         </Fragment>
//     }
// }

// export default SmartImage;