import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { closeMenu } from "../../actions";
import {
  Container,
  Header,
  Section,
  SectionHeader,
  Footer
} from "../../components/Layout";
import { SectionTitle, ScrollTop, PageFooter } from "../../components/Common";
import ProjectHero from "../../components/Projects/ProjectHero";

import projects from "../../data/projects";
import ProjectDescription from "../../components/Projects/ProjectDescription";
import ProjectDetails from "../../components/Projects/ProjectDetails";

class Project extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.closeMenu();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }
  render() {
    const singleProject = projects.find(
      project => project.id === parseInt(this.props.match.params.id)
    );
    return (
      <>
        <Header static />
        <ProjectHero project={singleProject} />
        <Section>
          <Container>
            <SectionHeader>
              <SectionTitle>O projekcie</SectionTitle>
            </SectionHeader>
            {singleProject.description.map((part, index) => (
              <ProjectDescription key={index}>{part}</ProjectDescription>
            ))}
            <ProjectDetails project={singleProject} />
          </Container>
        </Section>
        <PageFooter />
        <ScrollTop />
        <Footer />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeMenu }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(Project);
