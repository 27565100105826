import slugify from 'slugify';

const categories = [
    'Public relations',
    'Event marketing',
    'Content marketing',
    'Wsparcie sprzedaży',
]

const projects = [
    {
        id: 1,
        name: 'XXIII \r\nKongres \r\nSprzedażowy',
        client: 'Aviva',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Kongres Sprzedaży to doroczna gala, podczas której nagradzani są najlepsi przedstawiciele towarzystwa ubezpieczeniowego Aviva. Celem wydarzenia było przekazanie głównych przesłań grupy, budowanie świadomości marki, integracja lokalnych struktur sprzedaży z&nbsp;biurem głównym, wręczenie nagród za wyniki sprzedaży w&nbsp;2015, motywacja do pracy w&nbsp;kolejnym roku oraz podkreślenie roli doradcy.',
            'Przygotowaliśmy koncepcję kreatywną eventu, którego każdy element był osadzony w&nbsp;klimacie aviatorskim, począwszy od zaproszenia, przez scenografię, po załogę samolotu składającą się z&nbsp;głównych przedstawicieli Avivy. Koordynowaliśmy wydarzenie dla 500 osób, w&nbsp;tym logistykę ruchu na scenie i&nbsp;spersonalizowane animacje multimedialne dla laureatów. Wykorzystaliśmy do tego innowacyjne i&nbsp;w&nbsp;pełni interaktywne narzędzie – dedykowane oprogramowanie zsynchronizowane z&nbsp;kamerą kinect, dzięki któremu po wejściu laureata na scenę mogliśmy wyświetlić dowolny obiekt na ekranie poruszający się wraz z&nbsp;ruchem człowieka. Gościem specjalnym wydarzenia był Sebastian Kawa – wielokrotny mistrz świata w szybownictwie, a&nbsp;tuż przy scenie stanął jego szybowiec. Dla uczestników przygotowaliśmy szereg dodatkowych atrakcji, m.in. strefę podniebnych fotografii, gdzie mogli wziąć udział w&nbsp;stylizowanej sesji fotograficznej. Wydarzenie odbyło się&nbsp;w hotelu Double Tree Hilton',
        ],
        review: {
            author: 'Piotr Pamięta p.o. Menedżer Marketingowego Wsparcia Sprzedaży',
            position: '',
            company: '',
            content: ['Agencja wykazała się dużą wiedzą, elastycznością i profesjonalizmem. Współpraca przebiegała bezproblemowo, a efekty spełniły nasze oczekiwania.'],
        },
        photos: 6,
    },
    {
        id: 2,
        name: 'Projekt \r\nCzysta \r\nPlaża',
        client: 'Barefoot',
        category: slugify(categories[0], { lower: true }),
        description: [
            'Zarówno my, jak i&nbsp;nasi klienci doskonale zdajemy sobie sprawę z tego, że kondycja naszej planety leży tylko i&nbsp;wyłącznie w naszych rękach. W 2017 roku marka Barefoot po raz kolejny postanowiła przyczynić się do poprawy stanu polskich plaż akcją „Barefoot Projekt Czysta Plaża”. Ma on na celu edukację ekologiczną nt. problemu zaśmiecania oraz szerzenie idei odpowiedzialnego wypoczynku. Przedstawiciele projektu ruszają w Polskę i&nbsp;angażują tysiące wolontariuszy do wspólnej akcji sprzątania plaż miejskich oraz nadmorskich, tak aby można było przejść się po nich bezpiecznie bosą stopą.',
            'Nasza agencja odpowiadała w&nbsp;projekcie za współpracę z&nbsp;influencerami oraz komunikację. Za zadanie mieliśmy zachęcić jak najwięcej osób do wzięcia udziału w&nbsp;akcji oraz wygenerować jak największy zasięg medialny. W&nbsp;ramach akcji w&nbsp;3&nbsp;miejscach w&nbsp;Polsce odbyło się sprzątanie plaż. Do współpracy przy projekcie zaprosiliśmy Filipa Chajzera i&nbsp;Jankesa, którzy skutecznie promowali akcję, zachęcali do dołączenia nowych wolontariuszy i&nbsp;własnoręcznie razem z nimi sprzątali nadmorski piasek. W&nbsp;trakcie 3&nbsp;akcji w&nbsp;Gdańsku, Łebie i&nbsp;Warszawie, w&nbsp;sprzątaniu plaż wzięło udział łącznie 481 wolontariuszy, którzy zebrali prawie 300 kg śmieci. Efektem działań było 368 publikacji w mediach z&nbsp;łączną liczbą wyświetleń 4,5 mln.',
        ],
        photos: 6,
    },
    {
        id: 3,
        name: 'Carolina \r\nHerrera - \r\nGood Girl',
        client: 'SIROWA \r\nPOLAND',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Launch prasowy produktu związanego z&nbsp;dwoistością kobiecej natury został zorganizowany w&nbsp;formie panelu dyskusyjnego, w&nbsp;którym udział wzięły Lidia Popiel, Renata Kaczoruk oraz Ania Piszczałka. Moderatorem był Michał Piróg. W&nbsp;obecności dziennikarek i&nbsp;influencerek zaproszonych na wydarzenie odbyła się dyskusja o&nbsp;tym, czy posiadanie dwoistej natury pomaga, czy przeszkadza w życiu kobiety.',
            'Koncepcja panelu była strzałem w&nbsp;dziesiątkę przede wszystkim ze względu na zaproszonych Gości: dwie modelki, jednak o całkowicie różnych temperamentach i&nbsp;osobowościach, fotografka, której praca jest w&nbsp;ogromnym stopniu uzależniona od umiejętności korzystania modelek z dwoistości kobiecej natury oraz jeden mężczyzna mający wielkie doświadczenie w&nbsp;pracy z&nbsp;kobietami takimi jak postać, którą inspirowali się twórcy nowego zapachu. Całe wydarzenie inspirowane było bowiem nie tylko konceptem perfumeryjnym domu Carolina Herrera, ale także filmem, który powstał z&nbsp;okazji jego premiery, a&nbsp;w&nbsp;którym wzięła udział Karlie Kloss, modelka i ikona stylu. „Jestem grzeczną, czy niegrzeczną dziewczyną? To zależy od dnia...” – mówi Karlie w spocie reklamowym promującym zapach.',
            'Poza ciekawym scenariuszem, zadbaliśmy o&nbsp;elegancką, stonowaną scenografię eventu i&nbsp;wyeksponowanie pięknego flakonu perfum. Premiera prasowa zapachu Good Girl by Carolina Herrera odbyła się w przestrzeni eventowej Perfumerii Douglas w Arkadii.',
        ],
        photos: 4,
    },
    {
        id: 4,
        name: 'Sampling \r\nproduktu \r\nColon Slim',
        client: 'Colon Slim / \r\nOrkla Care',
        category: slugify(categories[3], { lower: true }),
        description: [
            'Nasza firma w 2019 roku została poproszona o&nbsp;wsparcie promocyjne marki Colon Slim, suplementu diety, który pozwala w&nbsp;efektywny i&nbsp;bezpieczny sposób zrzucić kilogramy i&nbsp;cieszyć się szczupłą sylwetką. Celem kampanii było wzbudzenie zainteresowania marką Colon Slim wśród trenerek personalnych – influencerek oraz ich podopiecznych i&nbsp;obserwatorów w&nbsp;mediach społecznościowych, a&nbsp;także dotarcie w niestandardowy sposób do grupy kobiet, które dbają o&nbsp;zdrowie, są aktywne i&nbsp;promują zdrowy styl życia.',
            'Projekt zakładał sampling sticków z&nbsp;suplementem poprzez trenerów personalnych – influencerów. Wybraliśmy tę grupę z&nbsp;uwagi na duże zainteresowanie odżywkami i&nbsp;suplementami oraz możliwość bezpośredniego dotarcia z&nbsp;suplementem Colon Slim do osób trenujących, czyli tych, które są bezpośrednią grupą docelową marki. Na potrzeby kampanii wykorzystaliśmy kilkadziesiąt tys. sticków z suplementem Colon Slim i&nbsp;zaprojektowaliśmy na nie specjalne koperty oraz torby w kolorystyce Colon Slim, ze szczegółowym opisem stosowania.',
            'Dzięki 15 trenerom koperty z suplementem Colon Slim dotarły do 575 osób. Influencerzy w&nbsp;sumie wygenerowali na Instagramie zasięg ponad 300 tys. oraz 15 tys. na FB. Opublikowano 15 postów na IG oraz 9 postów na FB i&nbsp;prawie 40 Instastories z&nbsp;przekierowaniem na stronę Colon Slim oraz fanpage na FB. Pod postami pojawiło się ponad 10 tys. lajków. Przeprowadziliśmy ankietę i&nbsp;otrzymaliśmy feedback w postaci odpowiedzi od 575 osób, które zastosowały Colon Slim.',
        ],
        review: {
            author: 'Klaudia Szymańczyk',
            position: 'Brand Manager',
            company: 'Orkla Care S.A.',
            content: [
                'Współpraca z agencją Brave Hearts przebiegała bardzo sprawnie i przyjemnie. Agencja Wykazała się kreatywnością i zaangażowaniem w prowadzony projekt. Przygotowane materiały były na bardzo wysokim poziomie pod względem estetycznym i jakościowym, co przełożyło się na pozytywne opinie wśród konsumentów i klientów. Pracowników Brave Hearts cechuje proaktywność, rzetelność i wysoki poziom kompetencji. Agencja w pełni spełniła nasze oczekiwania.',
                'Polecam agencję Brave Hearts jako godnego zaufania i profesjonalnego partnera w zakresie działań PR i influencer marketingu.',],
        },
        photos: 5,
    },
    {
        id: 5,
        name: 'Uniwersytet \r\nZdrowej Skóry',
        client: 'Dermalogica',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Ze względu na liczbę oraz różnorodność aktywnych składników w premierowej linii kosmetyków marki Dermalogica postanowiliśmy nadać jej wyjątkową oprawę. Na potrzeby wydarzenia został powołany Uniwersytet Zdrowej Skóry, do którego zaprosiliśmy ok. 30 influencerek na co dzień zajmujących się tematyką beauty.',
            'W&nbsp;dniu eventu uczestniczki zostały powitane przez Rektora Uniwersytetu (przedstawiciela marki), który wprowadził je w&nbsp;plan zajęć akademickich. Każda z blogerek otrzymała obrandowane atrybuty studenta takie jak biret i&nbsp;niezbędne materiały drukowane.',
            'Zadaniem adeptów Uniwersytetu było wzięcie udziału w 3&nbsp;panelach szkoleniowych – zdobycie wiedzy z danego zakresu, a&nbsp;następnie podejście do testu sprawdzającego. Wszystkie uczestniczki zdały egzaminy celująco!',
        ],
        review: {
            author: 'Patryk Patrzała',
            position: 'Dyrektor Zarządzający',
            company: 'Dermalogica',
            content: [
                'Dziękuję za dobrze wykonaną pracę związaną z naszym eventem. Wiem, że bardzo podobało się osobom, które były obecne i to najbardziej cieszy! 😊',],
        },
        photos: 5,
    },
    {
        id: 6,
        name: 'Królowa \r\nPiękna',
        client: 'Perfumerie \r\nDouglas',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Konkurs „Królowa Piękna” był podziękowaniem dla wszystkich klientek Douglas oraz zachętą do tego, aby czuły się piękne każdego dnia. Udział w konkursie gwarantowało zrobienie zakupów za kwotę min. 50 zł i&nbsp;podzielenie się przemyśleniami o&nbsp;tym co sprawia, że kobieta czuje się piękna każdego dnia. Spośród wszystkich przysłanych odpowiedzi Douglas wybrał dwie najpiękniejsze i&nbsp;31 marca 2017 roku ogłosił laureatów nagród głównych – dwóch samochodów marki Fiat 500.',
            'Tego dnia na terenie Perfumerii Douglas w Arkadii postawiliśmy na przyciągającą wzrok scenografię, której centrum stanowił nowy Fiat 500 w ultrakobiecym kolorze oraz strefa foto, w której Douglasowe klientki mogły się poczuć jak prawdziwe Królowe. Sesje zdjęciowe dla klientek na białym tronie były tylko jedną z atrakcji. Poza nimi, klientki brały udział w&nbsp;innych konkursach organizowanych przez markę, a&nbsp;dziejących się w&nbsp;sferze offline i&nbsp;online. Po całym dniu animacji, odbyło się uroczyste wręczenie nagród głównych przez Dyrektor Marketingu firmy.',
        ],
        photos: 4,
        video: 'WYi2tLLyxdM',
    },
    {
        id: 7,
        name: 'Anna Yake',
        client: 'Perfumerie \r\nDouglas',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Anna Yake to marka kosmetyków klasy Premium pochodząca z&nbsp;Japonii. Nasza agencja zorganizowała event, który miał na celu wzbudzenie zainteresowania marką i&nbsp;uczczenie jej powrotu do perfumerii Douglas. Udało się w&nbsp;niestandardowy i&nbsp;oryginalny sposób nawiązać do unikalnego japońskiego charakteru poprzez dobór odpowiedniej oprawy i&nbsp;scenografii.',
            'W&nbsp;spotkaniu, które odbyło się w&nbsp;przestrzeni eventowej perfumerii Douglas w&nbsp;Arkadii, uczestniczyły dziennikarki, które mogły wypróbować na żywo japońskie rytuały pielęgnacyjne.',
            'Dialog o&nbsp;urodzie z&nbsp;uczestniczkami eventu poprowadziły Marietta Żukowska oraz Rozalia Mancewicz. Pierwsza, która wcieliła się w&nbsp;rolę dziennikarki, jest pasjonatką kultury japońskiej, natomiast druga, mieszkała przez jakiś czas w&nbsp;Japonii i&nbsp;z&nbsp;zaangażowaniem opowiadała o&nbsp;kraju kwitnącej wiśni.',
        ],
        review: {
            author: 'Magdalena Wasilewska',
            position: 'Marketing Manager',
            company: 'Douglas',
            content: [
                'Bardzo miła współpraca z profesjonalną, pełną pomysłów ekipą. Agencja zarządzana przez ludzi od zadań specjalnych i niestandardowych rozwiązań. Propozycje agencji dotyczące organizowanych wspólnie eventów zawsze w 100% odzwierciedlają charakter poszczególnych marek. Agencja, która naprawdę słucha klienta.',],
        },
        photos: 6,
    },
    {
        id: 8,
        name: 'Mon \r\nGuerlain',
        client: 'Perfumerie \r\nDouglas',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Premiera prasowa perfum „Mon Guerlain” odbyła się w&nbsp;przestrzeni eventowej Douglas Perfumerie. Wyzwaniem było przystosowania powierzchni sklepu do zrealizowania tam eleganckiego eventu zamkniętego dla dziennikarek i&nbsp;influencerek. Klientowi zależało na przekazaniu sporej ilości informacji w&nbsp;ciekawy sposób, utrzymaniu stałej dynamiki wydarzenia, a&nbsp;także efekcie „wow”, który miał towarzyszyć ogłoszeniu nowej ambasadorki marki – Angeliny Jolie.',
            'Event podzielony był na 3&nbsp;części merytoryczne: pierwsza opowiadająca o&nbsp;historii flakonów Guerlain i&nbsp;o&nbsp;tym jak wpisuje się weń najnowszy z&nbsp;nich, druga opowiadała o&nbsp;historii zapachów, trzecia dotyczyła wyłącznie Angeliny Jolie i&nbsp;jej współpracy z&nbsp;marką. Przestrzeń w&nbsp;perfumerii zaaranżowaliśmy adekwatnie do wszystkich 3&nbsp;etapów eventu, natomiast ogłoszeniu sławnej ambasadorki towarzyszyło spektakularnej odsłonięcie jednej z&nbsp;ścian, na której nadrukowaliśmy zdjęcie Angeliny promujące zapach „Mon Guerlain”. Event poprowadził Training Manager marki, niezrównany Sebastian Bukowski wraz z&nbsp;Omeną Mensah, która posiada wiele cech wspólnych z&nbsp;samą Angeliną, m.in. prowadzi fundację Omenaa Foundation, która buduje szkoły i&nbsp;wspiera dzieci w Ghanie.',
        ],
        review: {
            author: 'Magdalena Wasilewska',
            position: 'Marketing Manager',
            company: 'Douglas Perfumerie',
            content: [
                'Z agencją Brave Hearts współpracujemy już od kilku lat i zawsze nasza współpraca przebiegała bez zarzutu.Zespół pracujący w Brave Hearts jest niezwykle kreatywny oraz rozumiejący specyfikę i potrzeby klienta. Projekty i wykonanie zawsze na czas i na bardzo wysokim poziomie. Dotyczy do zarówno małych projektów, jak i dużych, medialnych wydarzeń.',
            ],
        },
        photos: 5,
    },
    {
        id: 9,
        name: 'Konferencja dla \r\ndostawców 2017',
        client: 'Eurocash S.A.',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Konferencja dla Dostawców Grupy Eurocash to ważny punkt w&nbsp;kalendarzu firmy, wydarzenie co roku gromadzi około 250 przedstawicieli czołowych firm z branży FMCG w Polsce.',
            'Rozbudowaną część merytoryczną konferencji podzieliliśmy na 3&nbsp;osobne moduły, które odkrywaliśmy przed Gośćmi wydarzenia w&nbsp;spektakularny multimedialnie sposób. 3&nbsp;prelegentów wystąpiło na tle 3&nbsp;wielkoformatowych ekranów, które na koniec połączyły się w&nbsp;całość, umożliwiającą wyświetlenie trójstronnej, panoramicznej animacji o&nbsp;świecie Eurocash. Goście śledzili przebieg eventu siedząc na obrotowych krzesłach, dzięki którym zawsze byli ustawieni frontem do prelegenta i&nbsp;treści na ekranie.',
            'Finałem eventu było zaproszenie wszystkich do świata firmy i&nbsp;brandów Grupy. Jeden z&nbsp;ekranów uniósł się i&nbsp;ukazał drugą, wystawienniczą przestrzeń eventu, do której Goście weszli przechoząc przez scenę.',
        ],
        review: {
            author: 'Anna Święcicka',
            position: 'Kierownik ds. komunikacji Grupy Eurocash',
            company: '',
            content: [
                'Pracownicy agencji Brave Hearts bardzo dobrze wywiązali się z postawionych zadań, ponadto na każdym etapie projektu służyli wsparciem, wiedzą i dużą pomysłowością, dzięki czemu event miał zaskakujący przebieg i został bardzo dobrze oceniony przez Zarząd Grupy Eurocash oraz zaproszonych gości. Polecam Agencję Brave Hearts jako solidnego partnera z obszaru event marketingu i komunikacji wewnętrznej.'],
        },
        photos: 5,
        video: 'YzvtZcRbS-0',
    },
    {
        id: 10,
        name: 'Otwarcia \r\nperfumerii \r\nDouglas',
        client: 'Perfumerie \r\nDouglas',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Douglas to sieć bardzo popularnych perfumerii, która co roku otwiera swoje nowe salony w&nbsp;całej Polsce. W 2017 roku, na przestrzeni kilku miesięcy, dla marki Douglas zorganizowaliśmy 5&nbsp;otwarć nowych perfumerii – w&nbsp;Warszawie, Wrocławiu, Rzeszowie, Lublinie i&nbsp;Tychach. Celem wydarzeń było wzbudzenie jak największego zainteresowania i&nbsp;zwiększonego ruchu w perfumerii, który przełoży się na wzrost sprzedaży. Dzięki ciekawym aktywacjom mieliśmy zbudować świadomość nowej lokalizacji perfumerii w mieście i&nbsp;wypromować wydarzenie w mediach społecznościowych.',
            'Scenariusz otwarć oparliśmy o&nbsp;współpracę z&nbsp;zasięgowymi influencerkami. Przy wydarzeniach współpracowaliśmy z: La Makeup e&nbsp;bella, Call me blondiee, Patrycją Tyszką oraz Styloly. Blogerki z&nbsp;branży urodowej miały za zadanie zapowiedzieć otwarcie, a&nbsp;następnie podczas wydarzenia przeprowadzać konsultacje makijażowe i&nbsp;urodowe oraz spotykać się ze swoimi czytelniczkami.',
            'Oprócz działań z&nbsp;influencerkami, podczas każdego z&nbsp;eventów dbaliśmy o&nbsp;scenografię catering, a&nbsp;także inne aktywności zachęcające do odwiedzenia perfumerii. Podczas każdego z&nbsp;otwarć klientki perfumerii mogły wziąć udział w&nbsp;sesji, po której otrzymywały okładkę magazynu Douglas ze swoim zdjęciem. Perfumeria zapewniała również bezpłatne makijaże i&nbsp;porady stylistyczne.',
            'W&nbsp;efekcie we wszystkich nowo otwartych perfumeriach pojawiły się tysiące osób, posty dotyczące otwarć opublikowane przez influencerki otrzymały łącznie prawie 23 tys. polubień, a&nbsp;filmy udostępnione przez nie na YouTube mają ponad 70 tys. wyświetleń.',
        ],
        review: {
            author: 'Magdalena Wasilewska',
            position: 'Marketing Manager',
            company: 'Douglas Polska Sp z.o.o',
            content: [
                'Pracownicy agencji są elastyczni, natychmiast reagują na wprowadzane zmiany i zawsze osiągają zamierzone w briefie cele. Cenimy tę agencję za świeżość pomysłów oraz client service, którego standard odróżnia tę firmę od konkurencji.',
                'Polecamy agencję Brave Hearts jako najlepszego partnera w zakresie event marketingu.'],
        },
        photos: 5,
    },
    {
        id: 11,
        name: 'Konferencja dla \r\ndostawców 2018',
        client: 'Eurocash S.A.',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Konferencja dla dostawców Grupy Eurocash to doroczne wydarzenie biznesowe skierowane do producentów branży FMCG. Konferencja organizowana jest dla partnerów biznesowych Grupy Eurocash – dyrektorów marketingu i&nbsp;sprzedaży największych firm w&nbsp;Polsce, łącznie dla ok. 250 osób. Event miał składać się z&nbsp;dwóch części – oficjalnej, podczas której wszystkim uczestnikom prezentowane będą treści merytoryczne dotyczące współpracy z&nbsp;Grupą Eurocash oraz networkingowej, na którą zapraszani są wybrani goście.',
            'Odpowiadaliśmy za kompleksową organizacją eventu, w&nbsp;tym rekomendację miejsca, oprawę techniczną i&nbsp;nadzór reżyserski nad konferencją oraz wsparcie organizacyjne drugiej części spotkania. Zaproponowaliśmy niestandardową, nowoczesną oprawę multimedialną(łącznie prawie 200m2 ekranów diodowych ułożonych w&nbsp;formie kubika) oraz trójwymiarowe animacje eksponujące to ustawienie. Goście mogli śledzić prezentacje z&nbsp;wysokości, siedząc na eleganckich trybunach. Obie części były realizowane w&nbsp;tym samym dniu, w&nbsp;różnych lokalizacjach w&nbsp;Warszawie.',
            'Uczestnicy wydarzenia w ankiecie zrealizowanej bezpośrednio po wydarzeniu wysoko ocenili jego organizację. Według nich event miał zaskakującą, nowoczesną formułę, a&nbsp;merytoryka została zaprezentowana w&nbsp;sposób dynamiczny i&nbsp;angażujący. Była to nasza druga współpraca z&nbsp;Eurocash przy realizacji Konferencji dla dostawców – pierwszą zorganizowaliśmy rok wcześniej.',
        ],
        review: {
            author: 'Marta Gampf',
            position: 'Dyrektor Marketingu Grupy Eurocash',
            company: '',
            content: [
                'Jesteśmy bardzo zadowoleni ze współpracy z Agencją Brave Hearts, ich zaangażowanie i profesjonalizm były widoczne na każdym etapie przygotowań oraz podczas samej realizacji wydarzenia. Oceniamy ich pracę wysoko, podobnie jak uczestnicy wydarzenia, którzy poinformowali nas o tym w ankiecie bezpośrednio po wydarzeniu. Powtarzały się opinie, iż event miał zaskakującą, nowoczesną formułę, a merytoryka została zaprezentowana w sposób dynamiczny i angażujący.'],
        },
        photos: 5,
        video: 'Wg-zrHVQNME',
    },
    {
        id: 12,
        name: 'Projekt \r\ngraficzny \r\nstrony www',
        client: 'Eurocash S.A.',
        category: slugify(categories[2], { lower: true }),
        description: [
            'Projekt graficzny strony www miał być uzupełnieniem rozbudowanej architektury strony www naszego klienta. Ze względu na różnorodność formatów i&nbsp;dywersyfikację działalności Grupy Eurocash ważnym było, aby osoby wchodzące na stronę w&nbsp;łatwy sposób odnalazły to, czego szukają. Atrakcyjny design połączony z&nbsp;aktualnymi trendami w&nbsp;projektowaniu stron www zapewniły stronie czytelność.',
        ], review: {
            author: 'Magdalena Bajer',
            position: 'Kierownik ds. komunikacji w Grupie Eurocash',
            company: '',
            content: [
                'Ze względu na rozbudowaną strukturę Grupy, szeroki zakres jej działalności oraz różnorodność, którą wnosi każdy z konceptów specjalistycznych, stworzenie spójnej i intuicyjnej oprawy graficznej do strony internetowej było dużym wyzwaniem. Kluczowymi wartościami we współpracy z Brave Hearts była ich elastyczność, szybkość w reagowaniu oraz duża wiedza o naszej firmie. Mogę ich z całą pewnością polecić.'],
        },
        photos: 1,
    },
    {
        id: 13,
        name: 'Premiera \r\nperfum \r\nGuerlain',
        client: 'Diparlux',
        category: slugify(categories[0], { lower: true }),
        review: {
            author: 'Małgorzata Grabska',
            position: 'PR Manager w Diparlux',
            content: [
                'Z agencją Brave Hearts współpracujemy już od kilku lat i zawsze nasza współpraca przebiegała bez zarzutu. Zespół pracujący w Brave Hearts jest niezwykle kreatywny oraz rozumiejący specyfikę i potrzeby klienta. Projekty i wykonanie zawsze na czas i na bardzo wysokim poziomie.Dotyczy do zarówno małych projektów, jak i dużych, medialnych wydarzeń.'
            ]
        },
        description: ['Premierowe zapachy tej francuskiej marki są na tyle ekskluzywne, że dostępne jedynie w&nbsp;dwóch perfumeriach w&nbsp;Polsce. Przygotowany przez nas launch dwóch nowych linii zapachowych miał charakter kameralny, podkreślający unikatowość perfum oraz wyjątkowe cechy tych produktów.',
            'W&nbsp;premierowym evencie wzięły udział cztery czołowe polskie influencerki, których instagramowe publikacje śledzi na co dzień ponad 300 tysięcy osób. Prezentację w&nbsp;formie warsztatów przeprowadził Cyrille Jahin - ekspert marki Guerlain, który wprowadził gości w&nbsp;świat ośmiu nowych nut zapachowych L\'Art &&nbsp;La Matiere i&nbsp;trzech zapachów Absolus d\'Orient. Uczestniczki spotkania mogły wypróbować każdy z&nbsp;nich, dowiedzieć się jak dobrać odpowiedni dla siebie zapach, ale przede wszystkim porozmawiać w&nbsp;kameralnym gronie z&nbsp;ekspertem marki.',
            'Spotkanie odbyło się w&nbsp;eleganckim wnętrzu Artroom’u w&nbsp;H15 Boutique Hotel w&nbsp;Warszawie.'
        ],
        photos: 6,
    },
    {
        id: 14,
        name: 'Premiera \r\npodkładu \r\nGuerlain \r\nL\'Essentiel',
        client: 'Diparlux',
        category: slugify(categories[1], { lower: true }),
        review: {
            author: 'Małgorzata Grabska',
            position: 'PR Manager w Diparlux',
            content: [
                'Projekty i wykonanie zawsze na czas i na bardzo wysokim poziomie. Dotyczy do zarówno małych projektów, jak i dużych, medialnych wydarzeń.'
            ]
        },
        description: [
            'Nowy podkład Guerlain L’Essentiel oparty jest na naturalnych składnikach, dlatego koncepcję jego premiery oparliśmy na elementach kojarzonych z&nbsp;ekologią i&nbsp;produktach pochodzenia roślinnego. W&nbsp;spotkaniu premierowym udział wzięły 43 influencerki urodowe, które w sumie mają ponad 1,5 mln obserwatorów na Instagramie. Spotkanie składało się z&nbsp;części oficjalnej, prowadzonej przez wizażystę marki oraz atrakcji dodatkowych, które odbyły się w&nbsp;części nieoficjalnej. Wśród nich znalazł się warsztat jogi twarzy poprowadzony przez ekspertkę – Kasię Bem. Rozluźnione relaksacyjną praktyką uczestniczki spotkania mogły następnie na specjalnie do tego przygotowanych stanowiskach makijażowych dobrać idealny dla siebie odcień podkładu, a&nbsp;następnie otrzymać wybrany produkt w&nbsp;prezencie. Spotkanie odbyło się w&nbsp;restauracji Lif na warszawskim Mokotowie, którego wystrój z&nbsp;elementami bieli i&nbsp;drewna, uzupełniony przez dekoracje roślinne doskonale oddawał nastrój spotkania oraz charakterystyczne cechy nowego produktu Guerlain.',
        ],
        photos: 5,
    },
    {
        id: 15,
        name: 'Launch \r\npomadek \r\nRouge G',
        client: 'Guerlain',
        category: slugify(categories[0], { lower: true }),
        review: {
            author: 'Małgorzata Grabska',
            position: 'PR Manager w Diparlux',
            content: [
                'Z agencją Brave Hearts współpracujemy już od kilku lat i zawsze nasza współpraca przebiegała bez zarzutu.Zespół pracujący w Brave Hearts jest niezwykle kreatywny oraz rozumiejący specyfikę i potrzeby klienta.Projekty i wykonanie zawsze na czas i na bardzo wysokim poziomie. Dotyczy do zarówno małych projektów, jak i dużych, medialnych wydarzeń.'
            ]
        },
        description: [
            'Marka Guerlain stworzyła nową linię pomadek Rouge G – 30 odcieni, do których można dopasować 15 różnych, unikalnych etui.',
            'Naszym zadaniem była organizacja spotkania dla influencerów, podczas którego w duchu marki przedstawiliśmy nowy produkt. Spotkanie odbyło się w&nbsp;Studio Skandal, a&nbsp;zaproszonych na nie było ok. 30 wpływowych blogerek z&nbsp;branży beauty &&nbsp;lifestyle.',
            'Każda z&nbsp;uczestniczek miała okazję wylosować jedno z&nbsp;dostępnych na rynku unikalnych etui. Tutaj w&nbsp;personalizacji poszliśmy o&nbsp;krok dalej i&nbsp;na każdym z&nbsp;etui wygrawerowaliśmy wybrany przez gości napis. Części oficjalnej premiery produktu towarzyszyły także konkursy – jeden realizowany dla uczestniczek na miejscu i&nbsp;kolejny, do którego mogły one zaprosić swoich czytelników i&nbsp;widzów w&nbsp;social mediach.',
            'Produkt, a&nbsp;w&nbsp;szczególności możliwość wybrania spersonalizowanego etui, cieszył się ogromnym powodzeniem i&nbsp;wywołał pozytywne reakcje wśród gości eventu. Podczas jego trwania udostępnionych zostało 132 InstaStories z&nbsp;wizerunkiem nowej pomadki. Po wydarzeniu, goście opublikowali łącznie 38 postów w&nbsp;mediach społecznościowych (także na YouTube), które zdobyły łącznie prawie 10 000 polubień oraz wywołały ponad 700 komentarzy.',
        ],
        photos: 6,
    },
    {
        id: 16,
        name: 'Intranet',
        client: 'Eurocash S.A.',
        category: slugify(categories[2], { lower: true }),
        description: [
            'Intranet Grupy Eurocash to jedno z&nbsp;głównych narzędzi komunikacji wewnętrznej funkcjonujących w firmie. Zlecenie obejmowało projekt graficzny interfejsu użytkownika w wersji desktop i&nbsp;mobile oraz projekty materiałów promujących wdrożenie. Wyzwanie polegało na stworzeniu spójnej wizualnie z&nbsp;brandem i&nbsp;duchem marki identyfikacji, oraz ułatwieniu dostępu do najważniejszych informacji o&nbsp;firmie wszystkim pracownikom niezależnie od szczebla i&nbsp;lokalizacji.',
        ],
        photos: 2,
    },
    {
        id: 17,
        name: 'Otwarcie \r\nsalonu \r\niSpot \r\nPosnania',
        client: 'iSpot',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Podczas otwarcia nowej galerii Posnania swoje drzwi otworzył również kolejny salon iSpot. Salon ten to nie tylko miejsce zakupów – to także miejsce spotkań, szkoleń oraz nowych możliwości, w&nbsp;którym królują nowe technologie. Taki właśnie obraz salonu chcieliśmy przedstawić wszystkim gościom, którzy pojawili się tego dnia na otwarciu.',
            'W otwarcie zaangażowaliśmy influencerów. Do współpracy zaprosiliśmy osoby znane z&nbsp;odwagi i&nbsp;tego, że swoją karierę zrobiły dzięki przełamywaniu konwenansów – Michała Piróga, Jankesa oraz Łukasza Jakóbiaka. Ich zadaniem było stworzenie luźnej atmosfery, przełamanie barier i&nbsp;zachęcenie odwiedzających salon do wzięcia udziału w&nbsp;aktywnościach i&nbsp;konkursach. Pierwsi goście salonu, jeszcze przed oficjalnym otwarciem, mieli okazję go obejrzeć i&nbsp;nagrać unikalne filmiki na greenboxie – cinematography. Dla odwiedzających salon w&nbsp;późniejszych godzinach, po oficjalnym otwarciu, przygotowaliśmy konkurs i&nbsp;dosyć nietypową aktywność: Dance like nobody’s watching! Tym hasłem zachęciliśmy klientów do dobrej zabawy, a&nbsp;następnie postawiliśmy ich przed wysokimi lustrami weneckimi. Klienci salonu znajdujący się za lustrami mieli do dyspozycji iPoda i&nbsp;bezprzewodowe słuchawki, a&nbsp;za zadanie tańczyć przez minutę! Oczywiście, znajdując się za lustrem widzieli tylko własne odbicie, podczas gdy inni odwiedzający salon mogli oglądać ich szalony taniec. Każdy uczestnik zabawy dostawał od salonu drobny upominek, a&nbsp;każdy taniec był nagrywany. Dzięki temu pod koniec wydarzenia wybrane zostało kilka osób, których taniec najbardziej „zachwycił” naszych znanych jurorów i&nbsp;które następnie otrzymały nagrody specjalne. W&nbsp;efekcie do salonu przyszły setki osób, a aktywności cieszyły się bardzo dużym zainteresowaniem. Niemała była tu rola trójki prowadzących, którzy zgromadzili w salonie wielu fanów.',
        ],
        review: {
            author: 'Bartłomiej Janiszek',
            position: 'Marketing Manager',
            company: '',
            content: [
                'Jesteśmy zadowoleni z profesjonalizmu oraz ze standardów obsługi, które oferuje ta agencja, dlatego szczerze polecamy jej usługi.'],
        },
        photos: 5,
        video: 'EqctlPnEl24',
    },
    {
        id: 18,
        name: 'Projekt \r\nedukacyjny \r\niSupport',
        client: 'iSpot',
        category: slugify(categories[3], { lower: true }),
        description: [
            'iSpot dba o&nbsp;edukację swoich klientów. iSupport to unikalny projekt szkoleniowy zrealizowany we współpracy z&nbsp;siecią iSpot Apple Premium Reseller skierowany do studentów i&nbsp;młodych osób chcących rozwijać swoje kompetencje w pracy z&nbsp;komputerem, grafiką i&nbsp;działaniach w&nbsp;blogosferze. Celem projektu było zainteresowanie mediów warsztatami, dotarcie do grupy docelowej oraz wzmocnienie świadomości, że produkty w&nbsp;iSpot to nowoczesne narzędzia, które ułatwiają życie i&nbsp;są niezbędne w&nbsp;budowaniu i&nbsp;rozwoju kariery. Projekt promował iSpot jako miejsce, w którym młodzi przedsiębiorcy i&nbsp;studenci znajdą idealne dla siebie produkty z&nbsp;intuicyjnym oprogramowaniem. W&nbsp;ramach nowoczesnych warsztatów edukacyjnych ich uczestnicy zyskali wiedzę nt. poruszania się w&nbsp;świecie nowych technologii, którą będą mogli wykorzystać nie tylko w życiu codziennym, ale również w prowadzeniu biznesu, poszerzaniu kompetencji i&nbsp;rozwoju osobistym.',
            'Między wrześniem a&nbsp;listopadem 2016 roku odbyło się 17 szkoleń tematycznych.Odpowiadaliśmy za przygotowanie koncepcji kreatywnej projektu i&nbsp;kompleksową organizację szkoleń, w&nbsp;tym opracowanie planów oraz merytoryki, rekrutację uczestników, produkcję materiałów szkoleniowych oraz zapewnienie obsługi osobowej szkoleń. Koordynowaliśmy cykle szkoleniowe w&nbsp;4&nbsp;miastach. Zakres naszych działań obejmował również copywriting i&nbsp;promocję poprzez kampanię radiową i&nbsp;online. Patronaty nad projektem objęło 8&nbsp;mediów. Odpowiadaliśmy również za współpracę z&nbsp;blogerami (m.in. Andrzej Tucholski, Socjopatka, Wikilistka, Model’s Outfit) oraz konkursy z&nbsp;udziałem mediów i&nbsp;partnerów. W&nbsp;szkoleniach wzięło udział 275 osób, z&nbsp;których 95% stwierdziło, że spełniły one ich oczekiwania, a&nbsp;58% zadeklarowało chęć zakupu nowego urządzenia Apple. Wypracowane w&nbsp;ramach projektu publikacje online i&nbsp;w&nbsp;prasie osiągnęły w&nbsp;grupie docelowej zasięg na poziomie 3,8 mln.',
            'Udowodniliśmy, że experiential marketing może jednocześnie budować pozytywny wizerunek marki, przynosić szeroki rozgłos w&nbsp;mediach i&nbsp;generować wysokąkonwersję na sprzedaż. Atutami naszej koncepcji było bezpośrednie doświadczenie klienta z&nbsp;marką, zaangażowanie klienta, wywołanie pozytywnych emocji, możliwość przetestowania produktów, komunikacja w miejscu zakupu, wartość edukacyjna oraz karty podarunkowe.',
        ],
        review: {
            author: 'Aleksandra Niegowska',
            position: 'Marketing Lead',
            company: 'iSpot Apple Premuim Reseller',
            content: [
                'Z całą odpowiedzialnością mogę polecić Agencję Brave Hearts jako solidnego partnera z obszaru event&experiential marketingu, któremu można powierzyć całościową realizację projektu.'],
        },
        photos: 6,
    },
    {
        id: 19,
        name: 'Wysyłka \r\nkreatywna',
        client: 'Lactacyd / \r\nOmega \r\nPharma',
        category: slugify(categories[3], { lower: true }),
        description: [
            'W&nbsp;2018 roku firma Lactacyd wprowadziła na rynek całkiem nowy produkt – olejek do higieny intymnej.',
            'Naszym celem w tej kampanii było wzbudzenie zainteresowania marką Lactacyd oraz nowym produktem oferowanym przez markę wśród polskich przedstawicielek blogosfery oraz ich czytelniczek, poprzez zaprezentowanie w&nbsp;oryginalny i&nbsp;niestandardowy sposób właściwości oraz zalet nowego produktu Lactacyd Percious Oil. Dzięki jak największej ilości wpisów na blogach oraz w&nbsp;mediach społecznościowych chcieliśmy zbudować pozytywne skojarzenie, dzięki któremu kobiety lepiej zapamiętają markę i&nbsp;jej produkty.',
            'Projekt zakładał kreatywną wysyłkę do blogerek z&nbsp;kategorii beauty &&nbsp;lifestyle. Tajemniczo zapakowane pudełka, które trafiły w&nbsp;ręce blogerek, zawierały produkt Lactacyd Precious Oil oraz piękne wianki symbolizujące kobiecość, subtelność, niewinność, świeżość, intymność. Poszczególne elementy wianka nawiązywały do składników produktu, co zostało dokładnie wyjaśnione wewnątrz opakowania. Dodatkową motywacją do publikacji wpisu były dwa konkursy: pierwszy zorganizowany dla blogerek, którego przedmiotem było opublikowanie zdjęcia w&nbsp;wianku wraz z&nbsp;opisem i&nbsp;hasztagami konkursowymi, a&nbsp;drugi dla czytelniczek blogów, które miały za zadanie opublikowanie zdjęcia nawiązującego do hasła „Because I&nbsp;am precious” i&nbsp;opatrzenie go odpowiednimi hashtagami.',
            'Blogerki, które otrzymały produkt, doceniły przesyłkę – zarówno produkt, jak i&nbsp;sposób zapakowania. Chętnie też publikowały recenzje, angażowały się w&nbsp;dyskusję z&nbsp;czytelniczkami, odpowiadały na pytania dotyczące produktu, zachęcały do kupna i&nbsp;wypróbowania. Ich czytelniczki chętnie czytały recenzje i&nbsp;komentowały wpisy. Niedużym kosztem wygenerowaliśmy prawie 150 publikacji o&nbsp;produkcie i&nbsp;wywołaliśmy „szum medialny” w&nbsp;blogosferze. Wiele blogerek włączało się w&nbsp;dyskusje, czytało recenzje innych, wymieniało inne zastosowania produktu, a&nbsp;przeważająca część wpisów miała bardzo pozytywny wydźwięk',
        ],
        photos: 6,
    },
    {
        id: 20,
        name: 'Lokalni Herosi',
        client: 'Eurocash S.A.',
        category: slugify(categories[0], { lower: true }),
        description: [
            'Lokalni Herosi to druga edycja projektu z cyklu Herosi Polskiej Przedsiębiorczości, która w związku z&nbsp;100. rocznicą odzyskania przez Polskę niepodległości miała charakter prospołeczny. Eurocash przeznaczył prawie 1,5 mln zł na realizację inicjatyw społecznych zgłoszonych przez przedsiębiorców współpracujących z Grupą. Kampania skierowana była do właścicieli małoformatowych sklepów detalicznych takich jak: Lewiatan, ABC, Groszek, Delikatesy Centrum, Euro Sklep, Gama, ABC na kołach oraz drogerii Koliber. Kluczowym celem projektu było budowanie pozytywnego wizerunku niezależnych przedsiębiorców jako lokalnie zaangażowanych w swoich społecznościach. Akcja miała również na celu integrację przedsiębiorców z&nbsp;lokalnymi społecznościami.',
            'Nasza rola polegała na kompleksowej obsłudze projektu, która obejmowała kreację, strategię komunikacji (kampania radiowa, prasowa, internetowa i&nbsp;outdoorowa oraz kampania wewnętrzna – kanały klienta), stworzenie strony internetowej <a target="_blank" href="https://www.herosiprzedsiebiorczosci.pl/">https://www.herosiprzedsiebiorczosci.pl/</a>, obsługę social mediów oraz współpracę z&nbsp;domem mediowym.',
            'Projekt podzielony był na 3&nbsp;etapy: składanie wniosków, ogólnopolskie głosowanie oraz finał, po którym rozpoczęła się realizacja inicjatyw. Przedsiębiorcy mogli zgłaszać pomysły na inicjatywy społeczne wspólnie z&nbsp;wybranym partnerem – organizacją pozarządową lub instytucją publiczną. Od maja do lipca 2018, za pomocą generatora wniosków na stronie www, zgłaszali swoje pomysły z&nbsp;różnych obszarów tematycznych: kultura, sport, ekologia, przestrzeń wspólna i&nbsp;pomoc. Przedsiębiorcy walczyli o&nbsp;granty w&nbsp;trzech kategoriach finansowych: do 10 tys. zł – 89 grantów do wygrania, do 50 tys. zł – 10 grantów do 100 tys. zł – 1&nbsp;grant. Do ogólnopolskiego głosowania zakwalifikowały się 403 inicjatywy. Przez dwa miesiące sympatycy projektów mogli oddawać na nie głosy internetowe oraz paragonowe. <strong>W sumie wyłonionych zostało 100</strong> projektów społecznych, które otrzymały największe społeczne poparcie.',
            'O&nbsp;popularności projektu i&nbsp;wygenerowanym za pośrednictwem kampanii zaangażowaniu klientów i&nbsp;lokalnych społeczności świadczą jego wyniki: 1,5 mln oddanych głosów, ponad 0,5 mln przesłanych paragonów, ponad 2&nbsp;mln wejść na stronę www akcji, która miała potencjał dotarcia dzięki szeroko zakrojonej kampanii do niemal 30 mln Polaków.',
        ],
        review: {
            author: 'Marta Gampf',
            position: 'Dyrektor Marketingu Grupy Eurocash',
            content: [
                'Firma Brave Hearts w ramach 11-miesięcznej współpracy odpowiedzialna była za kompleksową organizację projektu, w tym przygotowanie strategii, komunikację wewnętrzną i zewnętrzną, kontakt z mediami. Ponadto we współpracy z podwykonawcami agencja przygotowała kreację, odpowiadała za projekt i wdrożenie strony herosiprzedsiebiorczosci.pl oraz wszystkich materiałów promocyjnych związanych z akcją, administrowała profilem Herosi Przedsiębiorczości na Facebooku, koordynowała zakup mediów.',
                'Dzięki umiejętnie dobranym narzędziom i właściwej egzekucji zostały osiągnięte wyznaczone KPI.',
                'W trakcie realizacji projektu, firma Brave Hearts wykazała się także dużą skutecznością w działaniu oraz rzetelnością w dotrzymywaniu złożonych terminów i obietnic. Między innymi dzięki tym cechom, w projekcie udało się osiągnąć kluczowe cele zakładane podczas jego planowania. Na tej podstawie rekomenduje firmę Brave Hearts jako partnera kompleksowo realizującego marketingowe projekty typu 360°.',
            ],
        },
        photos: 5,
    },
    {
        id: 21,
        name: 'Gala V edycji \r\nkonkursu \r\nDyrektor \r\nMarketingu',
        client: 'Mediarun',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Piąta już edycja gali wręczenia nagród, towarzyszącej konkursowi Dyrektor Marketingu Roku odbyła się w&nbsp;2015 roku w Arkadach Kubickiego Zamku Królewskiego w Warszawie. Na galę złożyły się część oficjalna oraz bankiet. W&nbsp;wydarzeniu wzięło udział ponad 250 osób z całej Polski.',
            'Nasza agencja odpowiadała za kompleksową realizację wydarzenia – począwszy od przygotowania scenariusza, poprzez doradztwo w&nbsp;zakresie doboru scenografii i&nbsp;multimediów oraz, przygotowanie animacji do oprawy wizualnej, aż po pozyskanie i&nbsp;kontakt z partnerami wydarzenia. W&nbsp;efekcie naszych działań uczestnicy gali, którzy są jej stałymi gośćmi uznali Galę za najlepszą edycję spośród zrealizowanych do tamtej chwili.',
        ],
        review: {
            author: 'Paweł Netczuk',
            position: 'Prezes Zarządu',
            company: '',
            content: [
                'Polecam Agencję Brave Hearts jako solidnego partnera z obszaru działań co-marketingowych, pozyskiwania sponsorów wydarzeń oraz event marketingu.',
            ],
        },
        photos: 5,
    },
    {
        id: 22,
        name: 'Nivea Repair \r\n& Targeted \r\nCare',
        client: 'Nivea',
        category: slugify(categories[1], { lower: true }),
        description: [
            'W&nbsp;2015 roku NIVEA wprowadziła na rynek nową generację produktów do pielęgnacji włosów, które regenerują włosy precyzyjnie w&nbsp;miejscach, które najbardziej tego wymagają. Premiera prasowa produktu miała podkreślić połączenie najnowszych trendów, technologii oraz rozwiązań w&nbsp;imię piękna.',
            'Stworzyliśmy koncepcję kreatywną zakładającą realizację wydarzenia w&nbsp;formie pokazu mody, odbywającego się na dwóch płaszczyznach: wybiegu oraz backstage Na wybiegu prezentowane były stylizacje przygotowane przez profesjonalnych fryzjerów, w&nbsp;drugiej części sali zorganizowane było studio, z&nbsp;którego zaproszeni goście mogli na żywo podglądać przygotowania do występu i&nbsp;rozmowy ze stylistami. W&nbsp;przerwach między prezentacjami ekspertów marki dotyczącymi technologii i&nbsp;formuły nowej linii produktów, łączono się z Hair Studio, z&nbsp;którego relację zdawała Paulina Sykut-Jeżyna. Wśród modelek gościem specjalnym była Kamila Szczawińska.',
            'Naszym zadaniem była kompleksowa obsługa wydarzenia wraz z&nbsp;koordynacją reżyserii pokazu mody i&nbsp;transmisji live, a&nbsp;także oprawy multimedialnej i&nbsp;scenograficznej. Odpowiadaliśmy również za koncepcję i&nbsp;produkcję materiałów reklamowych klienta oraz obsługę PR. W&nbsp;spotkaniu wzięło udział ponad 50 dziennikarzy i&nbsp;blogerów, co zaowocowało ok. 60 publikacjami prasowymi i&nbsp;online.',
        ],
        review: {
            author: 'Julia Kozak',
            position: 'Managing Director',
            company: '',
            content: [
                'W trakcie premierowego projektu dla Nivea pracownicy Brave Hearts wykazali się ogromną proaktywnością i zaangażowaniem. Pomimo wielu modyfikacji w trakcie przygotowań byli dostępni, chętni do pomocy i dzielenia się wiedzą. Dzięki tym wszystkim cechom efekt finałowy w postaci tego eventu przeszedł oczekiwania nasze i naszych gości.',
            ],
        },
        photos: 6,
    },
    {
        id: 23,
        name: 'Bieg \r\nPiastów w \r\nSzklarskiej \r\nPorębie',
        client: 'PKP Intercity',
        category: slugify(categories[1], { lower: true }),
        description: [
            'W&nbsp;2018 roku PKP Intercity promowało podczas letniego Biegu Piastów w&nbsp;Szklarskiej Porębie połączenia kolejowe w&nbsp;kierunkach górskich. W&nbsp;specjalnie przygotowanej przez nas strefie, opartej na dostosowanym do potrzeb wydarzenia kontenerze eventowym, dla uczestników Biegu Piastów przygotowaliśmy szereg atrakcji dedykowanych biegaczom i&nbsp;ich bliskim. Uczestnicy mogli wziąć udział w&nbsp;konkurencjach sprawnościowych związanych z&nbsp;bieganiem, np. na bieżni z&nbsp;użyciem ciśnieniomierza, dzięki któremu mogliśmy nagrodzić tych, których tętno pozostało najbliżej normy, mimo biegowego wyścigu. Dla dzieci przygotowaliśmy strefę zabaw, w&nbsp;której oprócz malowania i&nbsp;wycinania mogły wziąć udział w&nbsp;konkursach i&nbsp;zabawach ruchowych prowadzonych przez naszych animatorów. Wsparliśmy również PKP Intercity w&nbsp;przygotowaniu tablic informacyjnych, znakujących jedną z&nbsp;tras biegowych w&nbsp;pobliżu Polany Jakuszyckiej, której PKP Intercity zostało partnerem.',
        ],
        photos: 6,
    },
    {
        id: 24,
        name: 'Spotkanie sprzedażowe',
        client: 'Totalizator \r\nSportowy',
        category: slugify(categories[1], { lower: true }),
        description: [
            'Ogólnopolskie spotkanie sił sprzedaży Totalizatora Sportowego obejmowało część merytoryczną, aktywność teambuildingową i&nbsp;wieczorną integrację. Celem spotkania była prezentacja wyników i&nbsp;najnowszych produktów z&nbsp;portfolio firmy, ale również wzmocnienie relacji z&nbsp;partnerami handlowymi.',
            'Naszym zadaniem było stworzenie komfortowej atmosfery, dzięki której przekazywane treści staną się lepiej zapamiętane, a&nbsp;uczestnicy spotkania poczują się częścią ogólnopolskiego zespołu. Naszą koncepcję oparliśmy na elementach związanych z&nbsp;lotnictwem. Przygotowaliśmy spójną z&nbsp;motywem przewodnim eventu oprawę scenograficzną, techniczną i&nbsp;multimedialną hali TWK Służewiec. Odpowiadaliśmy za reżyserię i&nbsp;realizację części konferencyjnej, integracyjnej oraz eventu wieczornego. Dla gości spotkania przygotowaliśmy szereg atrakcji. Kilkunastoosobowa grupa szczęśliwców odbyła lot widokowy nad Warszawą. Pozostałe grupy w&nbsp;tym czasie uczestniczyły we wspólnej aktywności – konstruowaniu własnymi siłami modelu samolotu. Gościem spotkania i&nbsp;motywacyjnym mówcą był Sebastian Kawa, wielokrotny mistrz świata w szybownictwie. Podczas eventu część z&nbsp;gości mogła wsiąść do jego szybowca, który na tę okazję przyjechał specjalnie z&nbsp;południa Polski.',
        ], review: {
            author: 'Tomasz Cekała',
            position: 'Z-ca Dyrektora Departamentu Sprzedaży',
            company: '',
            content: [
                'Rekomendujemy Brave Hearts Sp. z o.o firmom szukającym agencji eventowej, jako godnego zaufania partnera świadczącego usługi na najwyższym poziomie.'
            ],
        },
        photos: 6,
    },
]

export default projects;