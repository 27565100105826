import React from 'react';
import { Link } from 'react-router-dom';

import './ProjectTile.scss';

const ProjectTile = (props) => {
    return <Link to={`/projekty/${props.project.id}`} className='project-tile' style={{ backgroundImage: `url('/assets/projects/${props.project.id}/photo_1.jpg` }} >
        <div className='project-tile-content'>
            <h3 className='project-title'>{props.project.name}</h3>
            <span className='project-client'>{props.project.client}<span className='client-arrow fa fa-arrow-right'></span></span></div>
    </Link>
}

export default ProjectTile;