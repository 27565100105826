import * as React from 'react';
import { Link } from 'react-router-dom';

import './Button.scss';

// interface ButtonProps {
//     to?: string;
//     click?: any;
//     primary?: boolean;
//     danger?: boolean;
//     success?: boolean;
//     outline?: boolean;
//     disabled?: boolean;
//     auto?: boolean;
//     type?: string;
//     text: string;
//     big?: boolean;
//     small?: boolean;
// }

const Button = (props) => {
    const className = `button ${props.primary ? 'button-primary' : ''} ${props.secondary ? 'button-secondary' : ''}`;
    return (props.to ?
        props.external ?
            <a role="button" target="_blank" href={props.to} className={className}>
                {props.arrowLeft && <span className='button-arrow fa fa-arrow-left'></span>}
                {props.text}
                {props.arrow && <span className='button-arrow fa fa-arrow-right'></span>}
            </a>
            :
            <Link className={className} to={props.to}>
                {props.arrowLeft && <span className='button-arrow fa fa-arrow-left'></span>}
                {props.text}
                {props.arrow && <span className='button-arrow fa fa-arrow-right'></span>}
            </Link>
        :
        <button type={props.type} disabled={props.disabled} onClick={props.click} className={className}>{props.text}</button>)
}

export default Button;