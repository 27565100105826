import React from 'react';
import { connect } from 'react-redux';
import { Container, Section } from '../../Layout';
import CompetenceDescription from '../ComptenceDescription';

import competences from '../../../data/competences';

import './CompetencesSection.scss';

const CompetencesSection = props => {
    const mapped = competences.map(competence => <CompetenceDescription key={competence.id} competence={competence} />);

    return (<Section className='competences'>
        <Container>
            {mapped}
        </Container>
    </Section>)
}

const mapStateToProps = (state) => (
    {
        active: state.competences.index
    }
);

export default connect(mapStateToProps)(CompetencesSection);