import React from 'react';
import { Logo, SocialButtons } from '../../Common';
import { Container, Row, Column } from '../../Layout';

import './Footer.scss';


const Footer = () => {
    return <footer className='footer'>
        <Container>
            <Row>
                <Column className='footer-section' sm={3}>
                    <Logo />
                </Column>
                <Column className='footer-section' sm={3}>
                    <h2 className='footer-section-title'>Adres</h2>
                   <span>Al. Jana Pawła II 61 lok. 122</span>
                    <span>01-031 Warszawa</span>
                </Column>
                <Column className='footer-section' sm={3}>
                    <h2 className='footer-section-title'>Kontakt</h2>
                    <a href="mailto:biuro@bravehearts.pl">biuro@bravehearts.pl</a>

                    <a href="tel:+48691911158">+48 691 911 158</a>
                </Column>
                <Column className='footer-section' sm={3}><h2 className='footer-section-title'>Znajdź nas na</h2>
                    <SocialButtons />
                </Column>
            </Row>
        </Container>
    </footer>
}

export default Footer;