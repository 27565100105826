export const hideLoader = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: 'FADE_LOADER' });
            setTimeout(() => {
                dispatch({ type: 'HIDE_LOADER' });
            }, 1100)
        }, 100)
    }
}
