import React from "react";

import "./Section.scss";

const Section = props => {
  return (
    <section
      className={`page-section ${props.className ? props.className : ""}`}
    >
      {props.children}
    </section>
  );
};

export default Section;
