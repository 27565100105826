import React from 'react';

import './Member.scss';

const Member = props => {
    return <div className={`member ${props.alternate ? 'alternate' : ''}`}>
        <div className='member-photo'>
            <img src={props.member.img} alt={props.member.name} />
        </div>
        <div className='member-info'>
            <h3 className='member-name'>
                <span>{props.member.name}</span>
                <span>{props.member.lastname}</span>
            </h3>
            <span>{props.member.position}</span>
            <a href={`tel:${props.member.phone}`}>{props.member.phone}</a>
            <a href={`mailto:${props.member.mail}`}>{props.member.mail}</a>
            <p className='member-about' dangerouslySetInnerHTML={{ __html: props.member.about }} />
        </div>
    </div>
}

export default Member;