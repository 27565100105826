import { combineReducers } from 'redux';
import menuToggleReducer from './menu-toggle-reducer';
import loaderReducer from './loader-reducer';
import competencesReducer from './competences-reducer';
import clientsReducer from './clients-reducer';
const appReducer = combineReducers({
    menuToggle: menuToggleReducer,
    loader: loaderReducer,
    competences: competencesReducer,
    clients: clientsReducer,
});

export default appReducer;