const initialState = {
    index: 1,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SELECT_COMPETENCE':
            return { ...state, index: action.payload }
        default:
            return state;
    }
}
