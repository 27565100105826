import React from 'react';
import { connect } from 'react-redux';
import './Loader.scss';

class Loader extends React.Component {
    state = {
        loaded: 0
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.loaded >= 74) clearInterval(this.interval);
            this.setState({
                loaded: this.state.loaded + 1
            });
        }, 200)
    }

    componentDidUpdate(prevProps) {
        if (this.props.fading && !prevProps.fading) {
            clearInterval(this.interval);
            const remaining = 100 - this.state.loaded;
            const time = 500 / remaining;

            this.interval = setInterval(() => {
                if (this.state.loaded >= 99) clearInterval(this.interval);
                this.setState({
                    loaded: this.state.loaded + 1,
                })
            }, time)
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className={`loader ${this.props.visible ? '' : 'hidden'}  ${this.state.loaded >= 100 ? 'fading' : ''}`}>
                <div className='indicator' style={{ width: `${this.state.loaded}%` }}></div>
                <span className='percent'>{`${this.state.loaded}%`}</span>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        visible: state.loader.visible,
        fading: state.loader.fading,
    }
);

export default connect(mapStateToProps)(Loader);