import React from 'react';
import './ScrollTop.scss';

class ScrollTop extends React.Component {
    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        return (
            <div className='scroll-top-container'>
                <button className='scroll-top' onClick={this.scrollTop}><span className='fa fa-2x fa-arrow-up'></span></button>
            </div>
        )
    }
}

export default ScrollTop;