import React from 'react';
import { Section, Container } from '../../Layout';

import './ProjectsVideo.scss';

const ProjectsVideo = () => {
    return <Section>
        <Container>
            <figure className='video-container'>
                <div className='video'>
                    <iframe src="https://www.youtube.com/embed/u-vP1VryY64" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </figure>
        </Container>
    </Section>
}

export default ProjectsVideo;