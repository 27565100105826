import React from 'react';

import './ButtonsContainer.scss';

const ButtonsContainer = props => {
    return <div className={`buttons-container ${props.center ? 'center' : ''}`}>
        {props.children}
    </div>
}

export default ButtonsContainer;