import React from 'react';
import { Column, Row } from '../../Layout';
import ProjectPhoto from '../ProjectPhoto';
import ProjectReview from '../ProjectReview';

const ProjectDetails = (props) => {
    let tiles_1 = [];
    let tiles_2 = [];

    const half = props.project.review && props.project.photos > 1 ? Math.floor(props.project.photos / 2) : Math.round(props.project.photos / 2);
    let breakpoint = props.project.review && props.project.photos > 1 ? half + 1 : half;

    if (props.project.photos > 2 && props.project.photos - breakpoint < 2) breakpoint--;

    for (let i = 1; i <= breakpoint; i++) {
        tiles_1.push(<ProjectPhoto key={`1_${i}`} img={`/assets/projects/${props.project.id}/photo_${i}.jpg`} />)
    }
    for (let i = breakpoint + 1; i <= props.project.photos; i++) {
        tiles_2.push(<ProjectPhoto key={`2_${i}`} img={`/assets/projects/${props.project.id}/photo_${i}.jpg`} />)
    }

    const tilesWithReview = props.project.review ? [...tiles_2.slice(0, 1), <ProjectReview review={props.project.review} />, ...tiles_2.slice(1)] : tiles_2;
    return (
        <Row>
            <Column sm={6}>
                {tiles_1}
            </Column>
            <Column sm={6}>
                {tilesWithReview}
            </Column>
        </Row>
    )
}

export default ProjectDetails;