import React from 'react';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import { Router as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import smoothscroll from 'smoothscroll-polyfill';

import { hideLoader } from './actions';
import reducers from './reducers';

import { Loader } from './components/Common';

import { About, Start, Project, Projects, Competences, Contact } from './views';

import './App.scss';

smoothscroll.polyfill();

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers);

export const history = createBrowserHistory({ basename: '/' });

class App extends React.Component {
  componentDidMount() {
    window.addEventListener('load', () => {
      store.dispatch(hideLoader());
    })
  }

  render() {
    return <Provider store={store}>
      <div className="app">
        <Loader />
        <Router history={history}>
          <Switch>
            <Route exact component={Start} path={"/"} />
            <Route exact component={Projects} path={"/projekty"} />
            <Route exact component={Projects} path={"/projekty/kategorie/:category"} />
            <Route component={Project} path={"/projekty/:id"} />
            <Route component={Competences} path={"/kompetencje"} />
            <Route component={About} path={"/o-agencji"} />
            <Route component={Contact} path={"/kontakt"} />
          </Switch>
        </Router>
      </div>
    </Provider>
  }
}

export default App;