import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeMenu } from '../../actions';
import { Header, Footer } from '../../components/Layout';
import { ScrollTop, PageFooter } from '../../components/Common';
import ProjectsList from '../../components/Projects/ProjectsList';
import ProjectsVideo from '../../components/Projects/ProjectsVideo';

class Projects extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            this.props.closeMenu();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })
    }

    render() {
        return <>
            <Header static />
            <ProjectsVideo />
            <ProjectsList />
            <PageFooter />
            <ScrollTop />
            <Footer />
        </>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeMenu }, dispatch)
}

export default connect(null, mapDispatchToProps)(Projects);