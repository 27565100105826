import React from 'react';

// interface Props {
//     children: React.ReactNode;
//     sm?: number;
//     md?: number;
//     lg?: number;
//     vertical?: boolean;
//     className?: string;
// }

const Column = (props) => {
    const classes = `${props.className ? props.className : ''}  ${props.size ? `col-${props.size}` : ''}  ${props.xs ? `col-xs-${props.xs}` : ''} ${props.sm ? `col-sm-${props.sm}` : ''} ${props.md ? `col-md-${props.md}` : ''}  ${props.lg ? `col-lg-${props.lg}` : ''} ${props.vertical ? 'vertical' : ''}`;
    return <div className={classes}>{props.children}</div>
}

export default Column;