import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeMenu } from '../../actions';
import { Header } from '../../components/Layout';
import Info from '../../components/Contact/Info';

class Contact extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            this.props.closeMenu();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })
    }
    render() {
        return <>
            <Header static />
            <Info />
        </>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeMenu }, dispatch)
}

export default connect(null, mapDispatchToProps)(Contact);