import React from 'react';
import { Logo, MenuToggle } from '../../Common';
import { Navbar, Container } from '../../Layout';

import './Header.scss';

const Header = (props) => {
    return <header className={`top-header ${props.static ? 'static' : ''}`}>
        <Container>
            <div className='top-header-content'>
                {props.light ? <Logo header primary /> : <Logo header secondary />}
                <Navbar />
                <MenuToggle />
            </div>
        </Container>
    </header>
}

export default Header;