import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleClients } from '../../../actions';
import { Section, Container, SectionHeader, ButtonsContainer } from '../../Layout';
import { Button } from '../../Common';

import './Clients.scss';

const Clients = (props) => {
    let clients = [];
    for (let i = 0; i <= (props.toggled ? 19 : 7); i++) {
        clients.push(<div className='client'><img src={`/assets/clients/client_${i}.jpg`} alt="Klient" /></div>);
    }
    return (<Section>
        <Container>
            <SectionHeader>
                <h3> Zaufali nam:</h3>
            </SectionHeader>
            <div className="clients">
                {clients}
            </div>
            <ButtonsContainer center>
                <Button click={props.toggleClients} secondary text={props.toggled ? 'Pokaż mniej' : 'Pokaż więcej'} />
            </ButtonsContainer>
        </Container>
    </Section>)
}

const mapStateToProps = (state) => (
    {
        toggled: state.clients.toggled
    }
);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ toggleClients }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);