import React from 'react';
import { SectionTitle, Button } from '../../Common';
import { Container, Section, SectionHeader, Column, Row } from '../../Layout';
import ProjectTile from '../ProjectTile';
import projects from '../../../data/projects';

const widths = [
    6, 4, 2, 4, 4, 6, 2
]

const groupWidths = [
    [8, 4],
    [4, 8],
]

const groupChildWidths = [
    [6, 6, 8, 4],
    [12],
]

const ProjectsPreview = () => {

    const mapped = [
        { projects: projects.slice(0, 3) },
        {
            hasChildren: true, children: [
                { projects: projects.slice(3, 7) },
                { projects: projects.slice(7, 8) },
            ]
        },
        {
            hasChildren: true, children: [
                { projects: projects.slice(8, 9) },
                { projects: projects.slice(9, 13) },
            ]
        }
    ];

    const desktop = window.matchMedia('(min-width: 768px)');

    const tiles = desktop.matches ? mapped.map((group, groupIndex) => <Row key={`group_${groupIndex}`}>
        {group.hasChildren ?
            group.children.map((child, childIndex) => <Column key={childIndex} sm={groupWidths[groupIndex % 2 ? 0 : 1][childIndex]}>
                <Row>
                    {child.projects.map((project, index) => <Column key={project.id} sm={groupChildWidths[(groupIndex + childIndex) % 2 ? 0 : 1][index]}><ProjectTile project={project} /></Column>)}
                </Row>
            </Column>)
            :
            group.projects.map((project, index) => <Column key={project.id} sm={widths[index]}><ProjectTile project={project} /></Column>)
        }
    </Row>) :
        <Row>{projects.map(project => <Column size={6}><ProjectTile project={project} /></Column>)}</Row>;

    return (
        <Section>
            <Container>
                <SectionHeader>
                    <SectionTitle>Nasze projekty</SectionTitle>
                    <Button primary to="/projekty" arrow text="Zobacz wszystkie" />
                </SectionHeader>
                {tiles}
            </Container>
        </Section>
    )
}

export default ProjectsPreview;