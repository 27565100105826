import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCompetence } from '../../../actions';

import competences from '../../../data/competences';

import './Competence.scss';
import ComptenceDescription from '../ComptenceDescription';

class Competence extends React.Component {

    isActive = (index) => {
        return index === this.props.active;
    }

    handleCompetenceClick = (competence) => {
        this.props.selectCompetence(this.props.index)
        setTimeout(() => {
            const offset = document.querySelector(`#${competence.slug}`).offsetTop - 50;
            window.scrollTo({
                top: offset,
                behavior: 'smooth',
            })
        })
    }

    render() {
        const competence = competences.find(competence => competence.id === this.props.index);
        return (
            <>
                <div onClick={() => this.handleCompetenceClick(competence)} id={competence.slug} className={`competence ${this.isActive(this.props.index) ? 'active' : ''} ${this.props.white ? 'white' : ''} ${this.props.gold ? 'gold' : ''} ${this.props.primary ? 'primary' : ''} ${this.props.black ? 'black' : ''}`}>
                    <img src={this.props.img} />
                    <span className='competence-title'>
                        <span className='competence-title-content'>
                            {this.props.title}
                        </span>
                    </span>
                </div>
                <ComptenceDescription bind competence={competence} />
            </>
        )
    }
}

const mapStateToProps = (state) => (
    {
        active: state.competences.index
    }
);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectCompetence }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Competence);