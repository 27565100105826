import React from 'react';
import { Box } from '../../Layout';
import './ProjectReview.scss';

const ProjectReview = (props) => {
    const { review } = props;
    return <Box className='project-review' secondary left>
        {review && <div className='project-review-content'>
            <em className='review'>{review.content.map(part => <p>{part}</p>)}</em>
            {review.author ? <span>{review.author}</span> : null}
            {review.author ? <span>{review.position}</span> : null}
            {review.author ? <span>{review.company}</span> : null}
        </div>}
    </Box>
}

export default ProjectReview;