import React from 'react';
import { Container, Row, Column } from '../../Layout';
import { HeroTitle, SocialButtons } from '../../Common';

import './Info.scss';

const Info = () => {
    const desktop = window.matchMedia('(min-width: 768px)');
    return <section className='contact-info'>
        <Container>
            <Row>
                <Column sm={5} className='hero-content'>
                    <HeroTitle>Kontakt</HeroTitle>
                    <h3 className='contact-title'>Dane teleadresowe:</h3>
                    <strong className='contact-name'>Brave Hearts Sp. z o. o. </strong>

                    <div className='contact-section'>
                        <span>Al. Jana Pawła II 61 lok. 122</span>
                        <span>01-031 Warszawa</span>
                    </div>
                    <div className='contact-section'>
                        <a href="mailto:biuro@bravehearts.pl">biuro@bravehearts.pl</a>
                        <a href="tel:+48691911158">+48 691 911 158</a>
                    </div>
                    <span className='contact-social-title'>Znajdź nas na</span>
                    <SocialButtons />
                </Column>
            </Row>
        </Container>
        <video className='hero-background' muted loop playsInline autoPlay poster="/assets/contact_bg.jpg">
            {desktop.matches ?
                <source src="/assets/contact_bg_720.mp4" type="video/mp4" />
                :
                <source src="/assets/contact_bg_480.mp4" type="video/mp4" />
            }
            <img src='/assets/contact_bg.jpg' alt="Brave Hearts" />
        </video>
    </section>
}

export default Info;