const initialState = {
    visible: true,
    fading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case 'HIDE_LOADER':
            return { ...state, visible: false }
        case 'FADE_LOADER':
            return { ...state, fading: true }
        default:
            return state;
    }
}
