import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../Common';
import './Box.scss';

const Box = (props) => {
    return (
        <div className={`box ${props.className ? props.className : ''} ${props.primary ? 'box-primary' : ''} ${props.secondary ? 'box-secondary' : ''} ${props.left ? 'box-left' : ''}`}>
            {props.primary ? <video className='box-background' muted playsInline loop autoPlay>
                <source src="/assets/spinning-top.mov" type="video/mp4" />
            </video> : null}
            {props.children && <div className="box-content">{props.children}</div>}
            {props.title && <span className='box-title'>{props.title}</span>}
            {props.to && <Button external={props.external} secondary={props.primary} primary={props.secondary} arrow to={props.to} text={props.label} />}
            {props.link && <Link className='box-link' to={props.link}><span className='link-content'>{props.linkLabel}{props.arrowDown && <span className='box-arrow fa fa-arrow-down' />} {props.arrowRight && <span className='box-arrow fa fa-arrow-right' />}</span> </Link>}
        </div>
    )
}

export default Box;