import React from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { history } from '../../../App';
import ProjectCategory from '../ProjectCategory';


import './ProjectCategories.scss';

const categories = [
    {
        label: 'Wszystkie', value: 'wszystkie'
    },
    {
        label: 'Public relations', value: 'public-relations'
    },
    {
        label: 'Event marketing', value: 'event-marketing'
    },
    {
        label: 'Content marketing', value: 'content-marketing'
    },
    {
        label: 'Wsparcie sprzedaży', value: 'wsparcie-sprzedazy'
    },
]

class ProjectCategories extends React.Component {
    state = {
        selectedValue: categories.find(category => category.value === this.props.match.params.category) || {
            label: 'Wszystkie', value: 'wszystkie'
        }
    }
    changeCategory = (category) => {
        history.push(`/projekty/kategorie/${category.value}`);
        this.setState({ selectedValue: category });
    }
    render() {
        const pills = categories.map(category => <ProjectCategory key={category.value} category={category} />);
        return <div className='project-categories'>
            <div className='categories-pills'>
                {pills}
            </div>
            <div className='categories-select'>
                <Select value={this.state.selectedValue} options={categories} placeholder="Wybierz kategorię" onChange={this.changeCategory} />
            </div>
        </div>
    }
}

export default withRouter(ProjectCategories);