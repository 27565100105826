import React from 'react';
import { connect } from 'react-redux';

import './CompetenceDescription.scss';

const CompetenceDescription = props => {
    return <div key={props.competence.id} className={`competence-description ${props.bind ? 'bind' : ''} ${props.active === props.competence.id ? '' : 'hidden'}`}>
        <h3 className={`competence-name ${props.competence.class}`}>{props.competence.title}</h3>
        <p dangerouslySetInnerHTML={{ __html: props.competence.text }} />
    </div>
}

const mapStateToProps = (state) => (
    {
        active: state.competences.index
    }
);

export default connect(mapStateToProps)(CompetenceDescription);