import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleMenu } from '../../../actions';

import './MenuToggle.scss';

const MenuToggle = (props) => {
    return (
        <div className={`menu-toggle ${props.toggled ? 'toggled' : ''}`} onClick={props.toggleMenu}>
            <span className="hamburger-inner"></span>
        </div>
    )
}

const mapStateToProps = (state) => (
    {
        toggled: state.menuToggle.toggled,
    }
);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ toggleMenu }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuToggle);