import React from 'react';

import './HeroTitle.scss';

const HeroTitle = props => {
    const content = <span className="hero-title-content">
        {props.children}
    </span>

    return <h2 className={`hero-title ${props.project ? 'project' : ''} ${props.accent ? 'accent' : ''}`}>
        {content}
    </h2>
}

export default HeroTitle;