import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeMenu } from '../../actions';
import { Header, Footer } from '../../components/Layout';
import CompetencesHero from '../../components/Competences/CompetencesHero';
import CompetencesSection from '../../components/Competences/CompetencesSection';
import Clients from '../../components/Competences/Clients';
import { ScrollTop, PageFooter } from '../../components/Common';

class Competences extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            this.props.closeMenu();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })
    }

    render() {
        const desktop = window.matchMedia('(min-width: 768px)');
        return <>
            <Header light />
            <CompetencesHero />
            {desktop.matches &&
                <CompetencesSection />
            }
            <Clients />
            <PageFooter />
            <ScrollTop />
            <Footer />
        </>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeMenu }, dispatch)
}

export default connect(null, mapDispatchToProps)(Competences);