import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import './Navbar.scss';

const links = [
    { to: '/', name: 'Start' },
    { to: '/kompetencje', name: 'Kompetencje' },
    { to: '/projekty', name: 'Projekty' },
    { to: '/o-agencji', name: 'O Agencji' },
    { to: '/kontakt', name: 'Kontakt' },
]

const Navbar = (props) => {
    return (
        <nav className={`navigation ${props.toggled ? 'toggled' : ''}`}>
            <ul>
                {links.map(link => <li key={link.name}>
                    <NavLink exact={true} to={link.to} onClick={props.toggleMenu} activeClassName='active'>{link.name}</NavLink>
                </li>)}
            </ul>
        </nav>
    )
}

const mapStateToProps = (state) => (
    {
        toggled: state.menuToggle.toggled,
    }
);


export default connect(mapStateToProps)(Navbar);