import React from 'react';
import { Container, Row, Column } from '../../Layout';
import { Button, HeroTitle } from '../../Common';

import './Hero.scss';

const Hero = () => {
    const desktop = window.matchMedia('(min-width: 768px)');
    return <section className='about-hero'>
        <Container>
            <Row>
                <Column sm={5} className='hero-content'>
                    <HeroTitle accent>O agencji</HeroTitle>
                    <p className='hero-description'>
                        Agencja Brave Hearts jest obecna na rynku od 2015 roku. Powstała z&nbsp;pasji do marketingu i&nbsp;waleczności w&nbsp;realizacji odważnych pomysłów. W&nbsp;ciągu czterech lat istnienia zbudowała solidne portfolio kilkudziesięciu obsługiwanych Klientów, takich jak: Citi Handlowy, Grupa Eurocash, Perfumerie Douglas, iSpot Apple Premium Reseller, Renault, PKP Energetyka, 3M i&nbsp;inni,&nbsp;a także wyspecjalizowała się we wszelkich formach engagement marketingu realizowanych w&nbsp;online i&nbsp;offline. Siłą agencji jest jej zespół, którego członkowie – związani z branżą nawet od kilkunastu lat – posiadają różnorodne kompetencje, uzupełniające się wzajemnie w projektach realizowanych dla naszych Klientów.
                    </p>
                </Column>
            </Row>
            {desktop.matches &&
                <img className='hero-background' src='/assets/bg_team.jpg' alt='Brave Hearts' />
            }
        </Container>
    </section>
}

export default Hero;