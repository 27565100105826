import React from 'react';

import './SocialButtons.scss';

const SocialButtons = () => {
    return (
        <div className='social-buttons'>
            <a href="https://www.facebook.com/agencjabravehearts/" target="_blank" className='social-button'><span className='fa fa-facebook-f'></span></a>
        </div>
    )
}

export default SocialButtons;