import React from 'react';
import { Container, Row, Column, ButtonsContainer } from '../../Layout';
import { Button, HeroTitle } from '../../Common';

import './ProjectHero.scss';

const ProjectHero = (props) => {
    const desktop = window.matchMedia('(min-width: 768px)');
    return <section className={`project-hero ${props.project.video ? 'has-video' : ''}`}>
        <Container>
            <Row>
                <Column sm={12} className='hero-content'>
                    <ButtonsContainer>
                        <Button className='hero-button' to='/projekty' text='Powrót' arrowLeft />
                    </ButtonsContainer>
                    <div className='project-info'>
                        <HeroTitle project>
                            {props.project.name}
                        </HeroTitle>
                        <span className='hero-subtitle'>
                            {props.project.client}
                        </span>
                    </div>

                </Column>
            </Row>
            {props.project.video && desktop.matches ?
                <div className='hero-background'>
                    <div className='video-container'>
                        <iframe src={`https://www.youtube.com/embed/${props.project.video}?mute=1&autoplay=1&loop=1&playlist=${props.project.video}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                :
                <div className='hero-background' style={{ backgroundImage: `url('/assets/projects/${props.project.id}/cover.jpg` }} />
            }
        </Container>
    </section>
}

export default ProjectHero;