const initialState = {
    toggled: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case 'TOGGLE_MENU':
            return { ...state, toggled: !state.toggled }
        case 'CLOSE_MENU':
            return { ...state, toggled: false }
        default:
            return state;
    }
}
