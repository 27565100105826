import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import slugify from 'slugify';

import './ProjectCategory.scss';

class ProjectCategory extends React.Component {
    makeSlug = (category) => {
        return slugify(category, { lower: true })
    }

    isActive = (slug) => {
        return (slug === 'wszystkie' && !this.props.match.params.category) || (this.props.match && slug === this.props.match.params.category);
    }

    render() {
        return <Link className={`project-category ${this.isActive(this.props.category.value) ? 'active' : ''}`} to={`/projekty/kategorie/${this.props.category.value}`}>{this.props.category.label}</Link>
    }

}

export default withRouter(ProjectCategory);