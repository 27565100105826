export default [
    { id: 1, class: 'primary', title: 'Public relations', slug: 'public-relations', text: 'Oferujemy projektowanie i wdrażanie działań PR, zarówno samodzielnych, jak i będących elementem większych kampanii wykorzystujących marketing mix. Realizujemy pełen zakres wsparcia medialnego oraz współpracy z&nbsp;influencerami.' },
    {
        id: 2, class: 'primary', title: 'Event marketing', slug: 'event-marketing', text: 'Tworzymy i realizujemy projekty eventowe o różnej skali i charakterze, począwszy od uroczystych gal, poprzez premiery prasowe, wyjazdy integracyjne i projekty outdoorowe, aż po działania z obszaru ambient marketingu.'
    },
    {
        id: 3, class: 'gold', title: 'Content marketing', slug: 'content-marketing', text: 'Zajmujemy się copywritingiem i oprawą wizualną materiałów zarówno do kanałów online, jak i papierowych wydawnictw firmowych. Tworzymy koncepcje oraz formaty i wdrażamy je w życie.'
    },
    {
        id: 4, class: '', title: 'Wsparcie sprzedaży', slug: 'wsparcie-sprzedazy', text: 'Projektujemy i realizujemy programy lojalnościowe, akcje promocyjne i samplingowe, a także konkursy i loterie, które angażując odbiorcę końcowego wspierają wyniki sprzedażowe naszych Klientów.'
    },
]