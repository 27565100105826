import React from 'react';
import { Container, Row, Column } from '../Layout';
import { Button, HeroTitle } from '../Common';

import './Hero.scss';

const Hero = () => {
    return <section className='hero'>
        <Container>
            <Row>
                <Column sm={5} className='hero-content'>
                    <HeroTitle>Naszą misją jest budowanie ZAANGAŻOWANIA!</HeroTitle>
                    <p className='hero-description'>
                        Specjalizujemy się w projektach, które angażują odbiorców – klientów, media, pracowników, kontrahentów i poprzez zachętę do interakcji budują porozumienie między marką a jej adresatami.

    </p>
                    <Button className='hero-button' to='/kompetencje' text='Poznaj nasze kompetencje' primary arrow />
                </Column>
            </Row>
        </Container>
        <video className='hero-background' muted playsInline loop autoPlay>
            <source src="/assets/home_bg.mov" type="video/mp4" />
        </video>
    </section>
}

export default Hero;