import React from 'react';
import { withRouter } from 'react-router-dom';
import { SectionTitle } from '../../Common';
import { Container, Row, Column, Section, SectionHeader } from '../../Layout';
import ProjectTile from '../ProjectTile';
import ProjectCategories from '../ProjectCategories';
import projects from '../../../data/projects';

const widths = [
    6, 4, 2, 4, 4, 6, 2
]

const groupWidths = [
    [8, 4],
    [4, 8],
]

const groupChildWidths = [
    [6, 6, 8, 4],
    [12],
]

class ProjectsList extends React.Component {
    render() {
        const filteredProjects = this.props.match.params.category && this.props.match.params.category !== 'wszystkie' ? projects.filter(project => project.category === this.props.match.params.category) : projects;

        const mapped = [
            { projects: filteredProjects.slice(0, 3) },
            {
                hasChildren: true, children: [
                    { projects: filteredProjects.slice(3, 7) },
                    { projects: filteredProjects.slice(7, 8) },
                ]
            },
            {
                hasChildren: true, children: [
                    { projects: filteredProjects.slice(8, 9) },
                    { projects: filteredProjects.slice(9, 13) },
                ]
            },
            {
                hasChildren: true, children: [
                    { projects: filteredProjects.slice(13, 17) },
                    { projects: filteredProjects.slice(17, 18) },
                ]
            },
            {
                hasChildren: true, children: [
                    { projects: filteredProjects.slice(18, 19) },
                    { projects: filteredProjects.slice(19, 23) },
                ]
            },
            {
                hasChildren: true, children: [
                    { projects: filteredProjects.slice(23, 24) },
                ]
            },
        ];

        const desktop = window.matchMedia('(min-width: 768px)');

        const tiles = desktop.matches ? mapped.map((group, groupIndex) => <Row key={`group_${groupIndex}`}>
            {group.hasChildren ?
                group.children.map((child, childIndex) => <Column key={childIndex} sm={groupWidths[groupIndex % 2 ? 0 : 1][childIndex]}>
                    <Row>
                        {child.projects.map((project, index) => <Column key={project.id} sm={groupChildWidths[(groupIndex + childIndex) % 2 ? 0 : 1][index]}><ProjectTile project={project} /></Column>)}
                    </Row>
                </Column>)
                :
                group.projects.map((project, index) => <Column key={project.id} sm={widths[index]}><ProjectTile project={project} /></Column>)
            }
        </Row>) :
            <Row>{filteredProjects.map(project => <Column size={6}><ProjectTile project={project} /></Column>)}</Row>;

        return (
            <Section>
                <Container>
                    <SectionHeader>
                        <SectionTitle accent>Projekty</SectionTitle>
                    </SectionHeader>
                    <ProjectCategories />
                    {tiles}
                </Container>
            </Section>
        )
    }
}

export default withRouter(ProjectsList);