import React from 'react';
import { Link } from 'react-router-dom';
import "./Logo.scss";

const Logo = (props) => <div className='brand-logo'>
    {props.header && <h1 className='hidden'>Brave Hearts - engagement marketing agency</h1>}
    <Link to='/'>
        {props.primary ?
            <img src="/assets/bravehearts.svg" alt="Brave Hearts - engagement marketing agency" /> :
            <img src="/assets/bravehearts_secondary.svg" alt="Brave Hearts - engagement marketing agency" />
        }
    </Link>
</div>

export default Logo;