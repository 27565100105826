import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeMenu } from '../../actions';

import { Header, Footer } from '../../components/Layout';
import Hero from '../../components/About/Hero';
import Team from '../../components/About/Team';
import ScrollTop from '../../components/Common/ScrollTop';
import { PageFooter } from '../../components/Common';

class About extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            this.props.closeMenu();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })
    }

    render() {
        return <>
            <Header />
            <Hero />
            <Team />
            <PageFooter />
            <ScrollTop />
            <Footer />
        </>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeMenu }, dispatch)
}

export default connect(null, mapDispatchToProps)(About);