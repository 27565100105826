import React from 'react';
import { Container, Section } from '../../Layout';
import { HeroTitle } from '../../Common';
import Member from './Member';

const members = [
    { id: 1, img: '/assets/marzena.jpg', name: 'Marzena', lastname: 'Witczyńska', position: 'CEO', phone: '+48 691 911 158', mail: 'mw@bravehearts.pl', about: 'Z branżą marketingową związana od ponad 10 lat. Przed założeniem Brave Hearts, pracowała jako dyrektor ds. eventów i&nbsp;projektów specjalnych w agencji McCann Worldgroup i&nbsp;Momentum Worldwide, a wcześniej jako dyrektor zarządzający w Mea Group. Wykładowca Akademii PRoto z&nbsp;zakresu Event Marketing i&nbsp;Public Relations, Mediarun Academy w&nbsp;zakresie experiential marketingu, trade marketingu i&nbsp;eventów. Członek kapituły konkursowej “Dyrektor marketingu roku” organizowanego przez Mediarun. ' },
    {
        id: 2, img: '/assets/kasia.jpg', name: 'Katarzyna', lastname: 'Florek', position: 'Managing Director', phone: '+48 666 735 115', mail: 'kf@bravehearts.pl', about: 'W branży marketingowej od 10 lat. Najpierw związana z agencją Mea Group, następnie McCann Worldgroup i Momentum Worldwide – w obu na stanowiskach kreatywnych. Odpowiedzialna za tworzenie konceptów dla takich klientów jak Mastercard, AmRest, Samsung, PKP Intercity czy Renault Polska. Współzałożycielka i dyrektor zarządzająca Brave Hearts.'
    },
    {
        id: 3, img: '/assets/agata.jpg', name: 'Agata', lastname: 'Wachowska', position: 'Senior Account Manager', phone: null, mail: 'aw@bravehearts.pl', about: 'Swoje wieloletnie doświadczenie w branży marketingowej zdobywała w agencjach specjalizujących się w content marketingu, gdzie przez wiele lat pełniła rolę Senior Account Managera i obsługiwała największe międzynarodowe firmy. Wie jak tworzyć i koordynować zaawansowane kampanie, aby spełniały swoje cele biznesowe, przy zachowaniu najwyższego poziomu estetyki.'
    },
    { id: 4, img: '/assets/nadia.jpg', name: 'Nadia', lastname: 'Sotirow', position: 'Senior Project Manager', phone: null, mail: 'ns@bravehearts.pl', about: 'Branżowo pełnoletnia – swoją przygodę z marketingiem rozpoczęła 18 lat temu. Od tamtej pory pracowała na stanowiskach PR w&nbsp;agencjach: PR, eventowej i&nbsp;reklamowej. Obsługiwała klientów z&nbsp;niemal wszystkich branż. Specjalizuje się w&nbsp;tworzeniu i&nbsp;realizacji projektów komunikacyjnych i&nbsp;eventowych.' },
]


const Team = () => {
    const mapped = members.map(member => <Member member={member} alternate={member.id % 2 === 0} key={member.id} />)
    return <Section>
        <Container>
            <HeroTitle accent>Zespół</HeroTitle>
            {mapped}
        </Container>
    </Section>
}

export default Team;